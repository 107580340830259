import { AxiosResponse } from "axios";
import { useState } from "react";
import { ImagesHelpers } from "./images";

export const useSubmitForm = ({ imageFields, updateId, createApiCall, updateApiCall, onSuccess }: {
    createApiCall: (args: any) =>
        Promise<AxiosResponse>,
    updateApiCall: (args: any) =>
        Promise<AxiosResponse>, imageFields?: { name: string, singleValue: boolean }[], updateId?: string, onSuccess: Function
}
): [boolean, (args: Record<string, any>) => void] => {
    const [loading, setLoading]: [boolean, Function] = useState(false);


    // data.image=data.image[0].response.
    const submit = (data: Record<string, any>) => {
        setLoading(true);
        if (imageFields) {
            imageFields.forEach(field => {
                console.log(field, data);
                data[field.name] = ImagesHelpers.formatForSubmit(data[field.name], field.singleValue);
            });
        }
        if (!updateId) {
            createApiCall(data).then(res => {
                onSuccess();
            }).finally(() => setLoading(false)).catch((err) => {
                console.error(err);
            });

        }
        else {
            updateApiCall({ id: parseInt(updateId), ...data }).then(res => {
                onSuccess();
            }).finally(() => setLoading(false)).catch((err) => {
                console.error(err);
            });
        }
    };
    return [loading, submit];
}