import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
const persistConfig = {
    key: 'root',
    storage,
}

export type State = {
    isLoggedIn: boolean;
    user: any;
    token?: string;
    tablesData?: any
};
const initialState: State = {
    isLoggedIn: false,
    tablesData: {},
    user: {},
};
const reducer = (state = initialState, { type, payload }: any) => {

    switch (type) {
        case Redux_Actions.login:
            return { ...state, isLoggedIn: true, token: payload.token, user: payload.user };
        case Redux_Actions.logout:
            return { ...state, isLoggedIn: false, token: null, user: null }
        case Redux_Actions.setTableFilter:
            return { ...state, tablesData: { ...state.tablesData, [payload.tableId]: { ...state.tablesData[payload.tableId], filter: payload.data } } }
        case Redux_Actions.setTableState:
            return { ...state, tablesData: { ...state.tablesData, [payload.tableId]: { ...state.tablesData[payload.tableId], state: payload.data } } }
        default:
            return state;
    }
};
const persistedReducer = persistReducer(persistConfig, reducer)
export const Redux_Actions = {
    login: 'login',
    logout: 'logout',
    setTableFilter: 'setTableFilter',
    setTableState: 'setTableState',
}
export const store = createStore(persistedReducer,
    //@ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
export const persistor = persistStore(store);
