import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import React, { useState } from 'react';
import { Base_Url } from '../api';
import { IMAGE_ROOT } from '../helpers/images';
import { store } from '../store';


const getBase64: (file: any) => Promise<string> = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result?.toString());
        reader.onerror = error => reject(error);
    });
}




export const FileUploader: React.FC<{ value?: [UploadFile], onChange?: (value: UploadFile[]) => void, limit?: number }>
    = ({ value = [], onChange, limit = 1 }) => {
        // const [fileList, setFileList] = useState([]);
        const [previewImage, setPreviewImage]: [any, any] = useState(null);
        const [previewVisible, setPreviewVisible]: [any, any] = useState(false);
        const handlePreview = async (file: UploadFile) => {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            setPreviewImage((file.url && IMAGE_ROOT + file.url.replace(IMAGE_ROOT, "")) || file.preview);
            setPreviewVisible(true);
        };
        const handleChange: ((args: any) => void) = ({ fileList }) => {
            fileList = fileList.map((f: UploadFile) => {
                if (f.response && f.response.path) {
                    f.url = f.response.path;
                }
                return f;
            })
            onChange && onChange(fileList);
        }
        const uploadButton = (
            <div>
                <PlusOutlined />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        const handleCancel = () => {
            setPreviewVisible(false);
        }
        return (
            <div className="clearfix">
                <Upload
                    withCredentials={false}
                    headers={{
                        "Authorization": `Bearer ${store.getState().token}`
                    }}
                    action={`${Base_Url}/file/upload`}
                    listType="picture-card"
                    fileList={value}
                    onPreview={handlePreview}
                    onChange={handleChange}
                >
                    {value.length >= limit ? null : uploadButton}
                </Upload>
                <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </div>
        );
    };