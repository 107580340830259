
import { DashboardOutlined, PlusOutlined, SketchOutlined, TagsOutlined, TeamOutlined, UnorderedListOutlined, PlusCircleFilled } from "@ant-design/icons/lib";
import React from 'react';
import { Button } from "antd";
import { Link } from "react-router-dom";
import { Api } from "./api";
import CollectionTable from "./components/CollectionTable";
import CollectionForm from "./components/CollectionForm";
import MaterialTable from "./components/MaterialTable";
import MaterialForm from "./components/MaterialForm";
import TagTable from "./components/TagTable";
import TagForm from "./components/TagForm";
import PageTable from "./components/PageTable";
import PageForm from "./components/PageForm";




const CategoriesForm = React.lazy(() => import("./components/CategoriesCreate"));
const CategoriesTable = React.lazy(() => import("./components/CategoriesList"));
const Dashboard = React.lazy(() => import("./components/Dashboard"));
const JewelleryForm = React.lazy(() => import("./components/JewelleryForm"));
const JewelleryTable = React.lazy(() => import("./components/JewelleryTable"));
const UsersForm = React.lazy(() => import("./components/UsersCreate"));
const UsersList = React.lazy(() => import("./components/UsersList"));
const PriceRequestTable = React.lazy(() => import("./components/PriceRequestTable"));
const PriceRequestForm = React.lazy(() => import("./components/PriceRequestForm"));


export const Routes: any = [
    {
        title: "Dashboard",
        component: Dashboard,
        icon: DashboardOutlined,
        hideFromMenu: false,
        breadcrumb: 'I love sandwiches',
        path: "/",
        isHomePage: true,
    },
    {
        title: "Users",
        icon: TeamOutlined,
        path: "/users",
        buttons: [
            <Link key="create" to="/users/create">
                <Button type="primary" icon={<PlusOutlined />}>Add User</Button>
            </Link>
        ],
        component: UsersList,
        routes: [
            {
                title: "Create",
                icon: PlusOutlined,
                path: "/users/create",
                component: UsersForm,
            },
            {
                title: "Update",
                hideFromMenu: true,
                path: "/users/update/:id",
                component: UsersForm,
            },
        ]
    },
    {
        title: "Tags",
        icon: TagsOutlined,
        path: "/tags",
        dataRoute: Api.tags.statistics,
        component: TagTable,
        buttons: [
            <Link key="create" to="/tags/create">
                <Button type="primary" icon={<PlusOutlined />}>Add Tag</Button>
            </Link>
        ],
        routes: [
            {
                title: "Create",
                icon: PlusOutlined,
                path: "/tags/create",
                component: TagForm,
            },
            {
                title: "Update",
                hideFromMenu: true,
                path: "/tags/update/:id",
                component: TagForm,
            },
        ]
    },
    {
        title: "Categories",
        icon: TagsOutlined,
        path: "/categories",
        dataRoute: Api.categories.statistics,
        component: CategoriesTable,
        buttons: [
            <Link key="create" to="/categories/create">
                <Button type="primary" icon={<PlusOutlined />}>Add Category</Button>
            </Link>
        ],
        routes: [
            {
                title: "Create",
                icon: PlusOutlined,
                path: "/categories/create",
                component: CategoriesForm,
            },
            {
                title: "Update",
                hideFromMenu: true,
                path: "/categories/update/:id",
                component: CategoriesForm,
            },
        ]
    },
    {
        title: "Items",
        icon: SketchOutlined,
        path: "/items",
        buttons: [
            <Link key="create" to="/items/create">
                <Button type="primary" icon={<PlusOutlined />}>Add Item</Button>
            </Link>
        ],
        component: JewelleryTable,
        routes: [
            {
                title: "Create",
                icon: PlusOutlined,
                path: "/items/create",
                component: JewelleryForm,
            },
            {
                title: "Update",
                hideFromMenu: true,
                path: "/items/update/:id",
                component: JewelleryForm,
            },
        ]
    },
    {
        title: "Collections",
        icon: TagsOutlined,
        path: "/collections",
        dataRoute: Api.collections.statistics,
        component: CollectionTable,
        buttons: [
            <Link key="create" to="/collections/create">
                <Button type="primary" icon={<PlusOutlined />}>Add Collection</Button>
            </Link>
        ],
        routes: [
            {
                title: "Create",
                icon: PlusOutlined,
                path: "/collections/create",
                component: CollectionForm,
            },
            {
                title: "Update",
                hideFromMenu: true,
                path: "/collections/update/:id",
                component: CollectionForm,
            },
        ]
    },
    {
        title: "Materials",
        icon: TagsOutlined,
        buttons: [
            <Link key="create" to="/materials/create">
                <Button type="primary" icon={<PlusOutlined />}>Add Materials</Button>
            </Link>
        ],
        path: "/materials",
        dataRoute: Api.materials.statistics,
        component: MaterialTable,
        routes: [
            {
                title: "Create",
                icon: PlusOutlined,
                path: "/materials/create",
                component: MaterialForm,
            },
            {
                title: "Update",
                hideFromMenu: true,
                path: "/materials/update/:id",
                component: MaterialForm,
            },
        ]
    },
    {
        title: "Price Requests",
        icon: UnorderedListOutlined,
        path: "/price-requests",
        component: PriceRequestTable,
        routes: [
        ]
    },
    {
        title: "Pages",
        icon: TagsOutlined,
        buttons: [

        ],
        path: "/pages",
        dataRoute: Api.pages.statistics,
        component: PageTable,
        routes: [
            {
                title: "Page Management",
                hidePageHeader: true,
                hideFromMenu: true,
                path: "/pages/update/:id",
                component: PageForm,
            },
        ]
    },
]