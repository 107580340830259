import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from './Table';
import { Api } from '../api';
import { Space } from 'antd';
import BasicTableFilter from './BasicTableFilter';
import { ImagesHelpers } from '../helpers/images';
//import { CategoriesFilter } from './CategoriesFilter';




const columns = [
    {
        title: "id",
        sorter: true,
        dataIndex: "_id",
        width: '10%',
        key: "_id",
        render: (text: string) => {
            return (<Link to={`/materials/update/${text}`}>{text}</Link>)
        }
    },
    {
        title: 'Image',
        dataIndex: 'image',
        key: "image",
        width: '10%',
        align: 'center' as 'center',
        render: (text: string) => {
            return (<img width={50} height={50} style={{ objectFit: "cover" }} alt="item" src={ImagesHelpers.formatAmazonImage(text, { width: 50 })} />)
        }
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: "name",
        sorter: true,
        width: '20%',
    },
];

const MaterialTable = () => {
    return (
        <Space style={{ width: '100%' }} direction="vertical">
            {/* <CategoriesFilter /> */}
            <Table
                tableId="materialsTable"
                deleteApiCall={Api.materials.delete}
                allowDelete={true}
                restoreApiCall={Api.materials.restore}
                actions={(record: any) => (
                    <Link to={`/materials/update/${record._id}`}>View</Link>
                )}
                Filter={BasicTableFilter}

                apiCall={Api.materials.table} columns={columns} />
        </Space>
    )
};

export default MaterialTable;