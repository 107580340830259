import React, { CSSProperties } from 'react';


export const FieldErrorMessage: React.FC<{ name: string, errors: any, style?: CSSProperties, formatName?: string }> = ({ name, errors, style = {}, formatName }) => {
    let currentError = errors[name];
    if (!currentError)
        return (null);
    return (<div style={{ color: 'red', ...style }}>
        <p>{currentError.message || `${formatName || name} is required`}</p>
    </div>)
}