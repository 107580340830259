import { message } from "antd";
import axios, { AxiosError, AxiosResponse } from 'axios';
import { CONFIGURATION } from "../config";
import { Helpers } from "../helpers";
import { Redux_Actions, store } from '../store';

export const Base_Url =
    Helpers.isProduction ? CONFIGURATION.api_url_production :
        CONFIGURATION.simulateProduction ?
            CONFIGURATION.api_url_production : CONFIGURATION.api_url_dev;
const axiosInstance = axios.create({
    baseURL: Base_Url,
    timeout: 600000,
    withCredentials: false
});
axiosInstance.interceptors.request.use(async config => {
    config.headers.Authorization = `Bearer ${store.getState().token}`;
    return config;
},
    error => Promise.reject(error));

axiosInstance.interceptors.response.use(function (response) {

    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, (error: AxiosError) => {

    // message.error()
    if (error.response) {
        if (error.response.status === 401) {
            message.error("You have been logged out from the server");
            store.dispatch({ type: Redux_Actions.logout })
        }
        if (error.response.data) {
            let err = error.response.data.message;
            if (err) {
                if (Array.isArray(err) && error.response.status === 400) {
                    err.forEach(i => {
                        message.error(i.msg);
                    });
                } else if (err) {
                    message.error(err);
                }

            } else
                message.error(error.toString());
        } else {
            message.error(error.toString());
        }
    } else {
        message.error(error.toString());
    }


    return Promise.reject(error);
});

export let Api = {
    upload: ({ formData }: any): Promise<AxiosResponse> => axiosInstance.post("/file/upload", formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    }),
    login: ({ username, password }: { username: string, password: string }): Promise<any> => axiosInstance.post("/login", {
        email: username,
        password
    }),
    logout: (): Promise<AxiosResponse> => axiosInstance.get("/logout"),
    users: {
        create: ({ ...args }: { args: any }): Promise<AxiosResponse> => axiosInstance.post("/users/create", args),
        table: (args: any): Promise<AxiosResponse> => axiosInstance.get("/users/table", { params: args }),
        single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/users/single/${id}`),
        update: ({ id, ...args }: { id: number, args: any }): Promise<AxiosResponse> => axiosInstance.put(`/users/update/${id}`, { ...args }),
        delete: (id: number): Promise<AxiosResponse> => axiosInstance.delete(`/users/delete/${id}`),
        restore: (id: number): Promise<AxiosResponse> => axiosInstance.post(`/users/restore/${id}`),
        resetPassword: ({ id, args }: { id: number, args: any }): Promise<AxiosResponse> => axiosInstance.put(`/users/reset/${id}`, { ...args }),
    },
    categories: {
        create: ({ ...args }: { args: any }): Promise<AxiosResponse> => axiosInstance.post("/categories/create", args),
        table: (args: any): Promise<AxiosResponse> => axiosInstance.get("/categories/table", { params: args }),
        statistics: (): Promise<AxiosResponse> => axiosInstance.get("/categories/statistics"),
        list: (): Promise<AxiosResponse> => axiosInstance.get("/categories/list"),
        single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/categories/single/${id}`),
        update: ({ id, ...args }: { id: number, args: any }): Promise<AxiosResponse> => axiosInstance.put(`/categories/update/${id}`, {
            ...args

        }),
        delete: (id: number): Promise<AxiosResponse> => axiosInstance.delete(`/categories/delete/${id}`),
        restore: (id: number): Promise<AxiosResponse> => axiosInstance.post(`/categories/restore/${id}`),
    },
    collections: {
        create: ({ ...args }: { args: any }): Promise<AxiosResponse> => axiosInstance.post("/collections/create", args),
        table: (args: any): Promise<AxiosResponse> => axiosInstance.get("/collections/table", { params: args }),
        statistics: (): Promise<AxiosResponse> => axiosInstance.get("/collections/statistics"),
        list: (): Promise<AxiosResponse> => axiosInstance.get("/collections/list"),
        single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/collections/single/${id}`),
        update: ({ id, ...args }: { id: number, args: any }): Promise<AxiosResponse> => axiosInstance.put(`/collections/update/${id}`, {
            ...args

        }),
        delete: (id: number): Promise<AxiosResponse> => axiosInstance.delete(`/collections/delete/${id}`),
        restore: (id: number): Promise<AxiosResponse> => axiosInstance.post(`/collections/restore/${id}`),
    },
    jewellery: {
        create: ({ ...args }: { id: number, args: any }): Promise<AxiosResponse> => axiosInstance.post(`/jewellery/create`, args),
        table: (args: any): Promise<AxiosResponse> => axiosInstance.get("/jewellery/table", { params: args }),
        single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/jewellery/single/${id}`),
        update: ({ id, ...args }: { id: number, args: any }): Promise<AxiosResponse> => axiosInstance.put(`/jewellery/update/${id}`, {
            ...args
        }),
        delete: (id: number): Promise<AxiosResponse> => axiosInstance.delete(`/jewellery/delete/${id}`),
        restore: (id: number): Promise<AxiosResponse> => axiosInstance.post(`/jewellery/restore/${id}`),
    },
    priceRequest: {
        create: ({ ...args }: { id: number, args: any }): Promise<AxiosResponse> => axiosInstance.post(`/price-request/create`, args),
        table: (args: any): Promise<AxiosResponse> => axiosInstance.get("/price-request/table", { params: args }),
        single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/price-request/single/${id}`),
        update: ({ id, ...args }: { id: number, args: any }): Promise<AxiosResponse> => axiosInstance.put(`/price-request/update/${id}`, {
            ...args
        }),
        delete: (id: number): Promise<AxiosResponse> => axiosInstance.delete(`/price-request/delete/${id}`),
        restore: (id: number): Promise<AxiosResponse> => axiosInstance.post(`/price-request/restore/${id}`),
    },
    materials: {
        create: ({ ...args }: { args: any }): Promise<AxiosResponse> => axiosInstance.post("/materials/create", args),
        table: (args: any): Promise<AxiosResponse> => axiosInstance.get("/materials/table", { params: args }),
        statistics: (): Promise<AxiosResponse> => axiosInstance.get("/materials/statistics"),
        list: (): Promise<AxiosResponse> => axiosInstance.get("/materials/list"),
        single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/materials/single/${id}`),
        update: ({ id, ...args }: { id: number, args: any }): Promise<AxiosResponse> => axiosInstance.put(`/materials/update/${id}`, {
            ...args

        }),
        delete: (id: number): Promise<AxiosResponse> => axiosInstance.delete(`/materials/delete/${id}`),
        restore: (id: number): Promise<AxiosResponse> => axiosInstance.post(`/materials/restore/${id}`),
    },
    tags: {
        create: ({ ...args }: any): Promise<AxiosResponse> => axiosInstance.post("/tags/create", args),
        table: (args: any): Promise<AxiosResponse> => axiosInstance.get("/tags/table", { params: args }),
        statistics: (): Promise<AxiosResponse> => axiosInstance.get("/tags/statistics"),
        list: (): Promise<AxiosResponse> => axiosInstance.get("/tags/list"),
        single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/tags/single/${id}`),
        update: ({ id, ...args }: { id: number, args: any }): Promise<AxiosResponse> => axiosInstance.put(`/tags/update/${id}`, {
            ...args

        }),
        delete: (id: number): Promise<AxiosResponse> => axiosInstance.delete(`/tags/delete/${id}`),
        restore: (id: number): Promise<AxiosResponse> => axiosInstance.post(`/tags/restore/${id}`),
    },
    pages: {
        create: ({ ...args }: any): Promise<AxiosResponse> => axiosInstance.post("/pages/create", args),
        table: (args: any): Promise<AxiosResponse> => axiosInstance.get("/pages/table", { params: args }),
        statistics: (): Promise<AxiosResponse> => axiosInstance.get("/pages/statistics"),
        list: (): Promise<AxiosResponse> => axiosInstance.get("/pages/list"),
        single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/pages/single/${id}`),
        update: ({ id, ...args }: { id: number, args: any }): Promise<AxiosResponse> => axiosInstance.put(`/pages/update/${id}`, {
            ...args

        }),

    }
};
